import Box from '@/components/ui/Box';
import Txt from '@/components/ui/Txt';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { withFragment } from '@liquorice/gql-utils';
import { gql } from '__generated__';
import * as styles from './Column.css';

export const COLUMN_FRAGMENT = gql(`
    fragment column on column_Entry {
        id
        content: htmlContentSimple
    }
`);

type ColumnOwnProps = {
  isHeadingRow?: boolean;
};

const Column = withFragment(COLUMN_FRAGMENT, (data, { isHeadingRow = false }: ColumnOwnProps) => {
  if (!data) return null;

  return (
    <Box as={isHeadingRow ? 'th' : 'td'} className={styles.root}>
      <Txt html>{cleanHtml(data.content)}</Txt>
    </Box>
  );
});

export default Column;
