import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Txt, { TxtProps } from '../ui/Txt';
import * as styles from './PageHeader.css';

export type PageLabelProps<D extends React.ElementType = 'span'> = TxtProps<
  D,
  { disabled?: boolean }
>;

export const PageLabel = <D extends React.ElementType>({
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disabled,
  ...props
}: PageLabelProps<D>) => {
  return (
    <Txt
      color="white"
      monoFont
      uppercase
      variant="tiny"
      {...mergePropsClassName(props, styles.pageLabel)}>
      {children}
    </Txt>
  );
};
