import { BLOCKS_FRAGMENT } from '@/gql/fragments/blocks.gql';
import { TypedDocumentNode } from '@apollo/client';
import { Typename } from '@liquorice/allsorts-craftcms-nextjs';
import { FragmentType, getFragmentData } from '__generated__';
import { BlocksFragment } from '__generated__/graphql';

export type MaskedBlock = FragmentType<typeof BLOCKS_FRAGMENT>;

export type RawBlocks = (MaskedBlock | null)[];

export type BlocksProps = { blocks?: RawBlocks | null };

export type BlockTypename = Typename<BlocksFragment>;

export type BlockMeta = {
  typename: BlockTypename;
  level?: number;
  inView?: boolean;
  index: number;
  first: boolean;
  // firstOfType: boolean;
  nthOfType: number;
  last: boolean;
  previousBlock?: MaskedBlock;
  inTimeline?: boolean;
  firstInTimeline?: boolean;
  lastInTimeline?: boolean;
  anchor?: string | null;
};

export type BlockProps<T> = {
  id: string;
  data: T;
  meta: BlockMeta;
};

export type MaskedBlockProps<T> = {
  data: FragmentType<TypedDocumentNode<T>>;
  meta?: BlockMeta;
};

export const createBlockMeta = <T>(blocks: RawBlocks): MaskedBlockProps<T>[] => {
  const nthOfTypeCount: Record<string, number> = {};

  return blocks
    .map((v, i) => {
      if (!v) return null;

      const data = getFragmentData(BLOCKS_FRAGMENT, v);
      const type = data.__typename;

      nthOfTypeCount[type] = type in nthOfTypeCount ? nthOfTypeCount[type] + 1 : 0;

      return {
        data: v,
        meta: {
          typename: type,
          nthOfType: nthOfTypeCount[type],
          index: i,
          first: i === 0,
          last: i === blocks.length - 1,
          anchor: `section-${i + 1}`,
        },
      };
    })
    .filter(Boolean) as MaskedBlockProps<T>[];
};
