import { withFragment } from '@liquorice/gql-utils';
import { gql } from '__generated__';
import Box from '../ui/Box';
import Row from './Row';
import * as styles from './Table.css';

export const TABLE_FRAGMENT = gql(`
    fragment table on table_Entry {
        id
        row {
            ...row
        }
    }
`);

const Table = withFragment(TABLE_FRAGMENT, (data) => {
  if (!data) return null;

  return (
    <Box as="table" className={styles.root}>
      {data?.row.map((row, i) => (
        <Row key={i} data={row} />
      ))}
    </Box>
  );
});

export default Table;
