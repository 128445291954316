import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';
import ExternalCardBlockOverview from '../ExternalCardBlock/ExternalCardBlock.Overview';
import OverviewCardBlock from '../OverviewCardBlock';

export const OVERVIEW_CARDS_BLOCK_FRAGMENT = gql(`
  fragment overviewCardsBlock on blocks_overviewCards_BlockType {
    overviewCardMultiple {
      ...overviewCardBlock
      ...externalCardBlock
    }
  }
`);

export const OverviewCardsBlock = createBlock(OVERVIEW_CARDS_BLOCK_FRAGMENT, ({ data, meta }) => {
  const items = excludeEmptyTypedElements(data.overviewCardMultiple);

  return (
    <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
      <Grid cx={{ rowGap: '3xl' }}>
        {items?.map((item, i: number) => {
          if (item.__typename === 'entryCard_Entry') {
            return (
              <Grid.Col key={i}>
                <OverviewCardBlock data={item} />
              </Grid.Col>
            );
          } else {
            return (
              <Grid.Col key={i}>
                <ExternalCardBlockOverview data={item} />
              </Grid.Col>
            );
          }
        })}
      </Grid>
    </BlockContainer>
  );
});

export default OverviewCardsBlock;
