import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks';
import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import { createBlock } from '@/lib/parsers/blocks';
import { parseColorScheme } from '@/lib/parsers/common';
import { parseLinkFieldMultiple } from '@/lib/parsers/common/parseLinkFieldMultiple';
import { gql } from '__generated__';
import * as styles from './RichTextBlock.css';

export const RICH_TEXT_BLOCK_FRAGMENT = gql(`
  fragment richTextBlock on blocks_richText_BlockType {
    heading
    content: htmlContentDefault
    textColorScheme {
      label
    }
    linkFieldMultiple {
      ...linkFieldMultiple
    }
  }
`);

const RichText = createBlock(RICH_TEXT_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading, content, linkFieldMultiple } = data;

  const textColor = parseColorScheme(data.textColorScheme);
  const links = parseLinkFieldMultiple(linkFieldMultiple);

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
        customAnchor: heading,
      }}>
      <BlockHeader
        maxWidth="none"
        heading={heading}
        HeadingProps={{ variant: 'h3', color: 'primary', cx: { mB: 'md' } }}
        content={content}
        ContentProps={{ className: styles.withRichText, color: textColor }}>
        <Box cx={{ width: { md: '50' } }}>
          {links?.map((link, i) => {
            return (
              <Btn
                key={i}
                cx={{ mT: 'xl' }}
                fullWidth
                variant="filled"
                color="primary"
                size="large"
                rounded="small"
                {...link}
              />
            );
          })}
        </Box>
      </BlockHeader>
    </BlockContainer>
  );
});

export default RichText;
