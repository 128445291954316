import Box from '@/components/ui/Box';
import { withFragment } from '@liquorice/gql-utils';
import { makeNonNullableArray, toBoolean } from '@liquorice/utils';
import { gql } from '__generated__';
import Column from '../Column';

export const ROW_FRAGMENT = gql(`
    fragment row on row_Entry {
        id
        headingRow: lightswitch
        column {
            ...column
        }
    }
`);

const Row = withFragment(ROW_FRAGMENT, (data) => {
  if (!data) return null;

  const isHeadingRow = toBoolean(data.headingRow);
  const columns = makeNonNullableArray(data.column);

  const row = (
    <Box as="tr">
      {columns.map((column, i) => (
        <Column key={i} data={column} isHeadingRow={isHeadingRow} />
      ))}
    </Box>
  );

  if (isHeadingRow) {
    return <Box as="thead">{row}</Box>;
  }

  return row;
});

export default Row;
